<template>
   <div>
      <div class="body grey-bg">
         <div class="row">
            <div class="col-md-6 text-center align-self-center">
               <div>
                  <div class="titleCurso">
                     <h1>Termos de <span>boas compras</span></h1>
                     <p>
                        Bem-vindo à nossa plataforma! Aqui, priorizamos a sua experiência de compra, garantindo transparência, 
                        segurança e satisfação. Nossos termos de boas compras refletem nosso compromisso em oferecer produtos de 
                        qualidade e um serviço excepcional. Ao navegar em nosso site, você pode confiar que estamos sempre buscando 
                        o melhor para você, nosso cliente valorizado. Estamos ansiosos para servi-lo e tornar sua jornada de compra 
                        conosco simples, confiável e gratificante.
                     </p>
                  </div>
               </div>
            </div>
            <div class="col-md-6 text-center">
               <img src="@/assets/admin/images/cleber.png" alt="Start image" width="300">
            </div>
         </div>
      </div>
      <div class="body">
         <div class="row">
            <div class="col-12">
               <div class="mt-2 p-2">
                  <span class="bullet">&bull;</span>
                  <span>
                     <b>Qualidade Garantida: </b>Todos os produtos em nosso catálogo são cuidadosamente selecionados e testados para garantir 
                     a mais alta qualidade possível. Estamos comprometidos em oferecer apenas produtos que atendam aos nossos padrões rigorosos.
                  </span>
               </div>
               <div class="mt-2 p-2">
                  <span class="bullet">&bull;</span>
                  <span>
                     <b>Transparência Total:</b> Nosso processo de compra é transparente em todos os aspectos. Desde os preços até as políticas 
                     de devolução, nos esforçamos para fornecer informações claras e acessíveis para que você possa fazer escolhas informadas.
                  </span>
               </div>
               <div class="mt-2 p-2">
                  <span class="bullet">&bull;</span>
                  <span>
                     <b>Segurança em Primeiro Lugar: </b>Sua segurança é nossa prioridade máxima. Utilizamos tecnologias de segurança avançadas para 
                     proteger suas informações pessoais e garantir transações seguras em nosso site.
                  </span>
               </div>

               <div class="mt-2 p-2">
                  <span class="bullet">&bull;</span>
                  <span>
                     <b>Atendimento ao Cliente Excepcional:</b> Nossa equipe de suporte está sempre pronta para ajudar. Se você tiver alguma dúvida, 
                     problema ou precisar de assistência, não hesite em entrar em contato conosco via discord. Estamos aqui para garantir sua total satisfação.
                  </span>
               </div>

               <div class="mt-2 p-2">
                  <span class="bullet">&bull;</span>
                  <span>
                     <b>Experiência de Compra Confiável:</b> Queremos que sua experiência de compra conosco seja positiva e sem complicações. Estamos 
                     empenhados em fornecer um ambiente de compra confiável e agradável, onde você possa encontrar exatamente o que procura, com facilidade e tranquilidade.
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'TermosCompras',
}

</script>

<style scoped>
.titleCurso h1 {
	text-transform: uppercase;
	font-size: 50px;
	line-height: 70px;
	margin-bottom: 20px;
	font-weight: bold;
}

.titleCurso h1 span {
   color: #dd0f20;
}

.titleCurso p {
	font-weight: 400;
	font-size: 14px;
	padding-left: 20px;
	line-height: 28px;
	margin: 30px 0;
}

.body {
   padding-left: 10vw;
   padding-right: 10vw;
   padding-top: 5vw;
   padding-bottom: 5vw;
}

.title {
   margin-bottom: 10vh;
}
</style>